/*
 * @Description: 时间过滤器
 * @Author: 汉文
 * @Date: 2021-11-04 19:33:32
 * @LastEditTime: 2021-11-04 19:35:47
 * @LastEditors: 汉文
 */
export default function date (dt) {
  return new Date(dt)
}
