var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mescroll-vue",
    _vm._g(
      _vm._b(
        {
          ref: "mescroll",
          attrs: { down: _vm.mescrollDown, up: _vm.mescrollUp },
          on: { init: _vm.mescrollInit },
        },
        "mescroll-vue",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }