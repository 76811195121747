<!--
 * @Description:
 * @Author: 汉文
 * @Date: 2021-11-09 18:40:04
 * @LastEditTime: 2023-11-22 17:47:39
 * @LastEditors: 汉文
-->
<template>
  <svg class="svg-icon" aria-hidden="true" @click="test">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconName({ iconClass }) {
      return `#icon-${iconClass}`
    },
  },
  methods: {
    test() {
      this.$emit('click')
    },
  },
}
</script>

<style>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
