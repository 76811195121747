/*
 * @Description: vuex 自动化
 * @Author: 汉文
 * @Date: 2021-11-08 20:59:24
 * @LastEditTime: 2024-05-22 18:36:41
 * @LastEditors: 汉文
 */
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)
const files = require.context('./modules', false, /\.js$/)
const modules = files.keys().reduce((p, c) => ({ ...p, [c.replace(/(\.\/|\.js)/g, '')]: files(c).default }), {})
const vuexLocal = new VuexPersistence({
  // storage: window.sessionStorage,
  storage: window.localStorage,
  reducer: (state) => ({
    direction: state.direction,
    token: state.token,
    questionList: state.questionList,
    questionRuleDtoList: state.questionRuleDtoList,
    answerList: state.answerList,
    questionIndex: state.questionIndex,
    pageLoading: state.pageLoading,
    endQuestion: state.endQuestion,
    trainning: state.trainning,
  }),
})
export default new Vuex.Store({
  // modules,
  state: {
    direction: 'forward', // 页面切换方向
    token: '',
    questionList: [],
    questionRuleDtoList: [],
    answerList: [],
    questionIndex: 0,
    pageLoading: false,
    endQuestion: false,
    trainning: false,
  },
  getters: {
    // initLink: (state) => state.page.initLink,
    // isLoading: (state) => state.page.isLoading,
    // sdkInfo: (state) => state.page.sdkInfo,
    // userData(state, getters) {
    //   return state.user.user
    //   // return getters['user/user']
    // },
    // vuex 全局getters引入局部
    // token () {
    //   return store.getters['user/token']
    // }
  },
  mutations: {
    // 更新页面切换方向
    updateDirection(state, direction) {
      state.direction = direction
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_QUESTION_LIST: (state, questionOList) => {
      state.questionList = questionOList
    },
    SET_ANSWER_LIST: (state, answerList) => {
      state.answerList = answerList
    },
    SET_QUESTION_INDEX: (state, questionIndex) => {
      state.questionIndex = questionIndex
    },
    SET_QUESTION_RULE_DTO_LIST: (state, questionRuleDtoList) => {
      state.questionRuleDtoList = questionRuleDtoList
    },
    SET_PAGE_LOADING: (state, pageLoading) => {
      state.pageLoading = pageLoading
    },
    SET_END_QUESTION: (state, endQuestion) => {
      console.log('endQuestion', endQuestion)
      state.endQuestion = endQuestion
    },
    SET_TRAINNING: (state, trainning) => {
      state.trainning = trainning
    },
  },
  actions: {},
  plugins: [vuexLocal.plugin],
})

// import { mapMutations, mapState } from 'vuex'
// computed: {
//   ...mapState(['userInfo', 'token']),
// },
// methods:{
//   ...mapMutations(['SET_USERINFO', 'SET_TOKEN', 'SET_BARLIST']),
// }
