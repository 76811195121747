/*
 * @Description: 项目全局混入
 * @Author: 汉文
 * @Date: 2021-11-04 19:47:10
 * @LastEditTime: 2021-11-20 11:33:18
 * @LastEditors: 汉文
 */
import Vue from 'vue'
import GLOBAL_MIXINS from './modules/globalMixins'
Vue.mixin(GLOBAL_MIXINS)
export { default as emotionList } from './modules/emotionList'
