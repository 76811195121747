import store from '@/store'
const token = process.env.VUE_APP_TOKEN
/**
 * @description: 问政策
 * @param {*} data {
	"conversationId": "",
	"content": "我是具有本区户籍的，参加本市基本医疗保险失业人员，我应该怎样获得补助？返回的答案不能少于3000字"
}
 * @return {*}
 */
export const conversationPolicy = (data) => {
  return fetch('/api/forward/large/model/wenzheng/conversation', {
    baseURL: '/api/forward',
    method: 'POST',
    body: JSON.stringify(data),
    // dataType:"text/event-stream",
    headers: {
      'Content-Type': 'application/json',
      Authorization: store.state.token || token,
    },
  })
}
export const conversationData = (data) => {
  return fetch('/api/forward/large/model/wenshu/conversation', {
    baseURL: '/api/forward',
    method: 'POST',
    body: JSON.stringify(data),
    // dataType:"text/event-stream",
    headers: {
      'Content-Type': 'application/json',
      Authorization: store.state.token || token,
    },
  })
}
