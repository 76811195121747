/*
 * @Description: app 模块
 * @Author: 汉文
 * @Date: 2021-11-08 21:43:31
 * @LastEditTime: 2021-11-19 23:35:17
 * @LastEditors: 汉文
 */
const state = {}
const mutations = {}
const actions = {}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
