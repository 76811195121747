import Vue from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import App from './App.vue'
import router from './router'
import store from '@/store'
import '@/assets/icons'
import '@/components'
import '@/api'
import '@/filters'
import '@/directives'
import '@/mixins'
import '@/util'
import dayjs from 'dayjs'
// import '@/core/vant'
import 'animate.css'

Vue.use(Vant)
import '@/assets/css/index.scss'
import 'lib-flexible'
import '@/router/permission' // 路由权限控制
import { Notify } from 'vant'
Vue.use(Notify)

Vue.prototype.$dayjs = dayjs
Vue.prototype.$EventBus = new Vue()
// import './util/vconsole'
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)
    function updateOnlineStatus(event) {
      const condition = navigator.onLine ? 'online' : 'offline'
      if (condition === 'online') {
        Notify({ type: 'success', message: '你的网络已连接' })
      } else {
        Notify({ type: 'danger', message: '你的网络已断开' })
      }
    }
  },
}).$mount('#app')
