/*
 * @Description:
 * @Author: 汉文
 * @Date: 2021-11-20 13:09:47
 * @LastEditTime: 2024-08-15 15:13:42
 * @LastEditors: 汉文
 */
import axios from 'axios'
import store from '@/store'
import { addPendingRequest, removePendingRequest, loading, errorHandle, tip } from './utils'

// 创建axios实例
const instance = axios.create({
  timeout: 1000 * 60,
  baseURL: '/api/examine',

  // cancelToken: source.token // 取消请求（比如你进入某个路由后，可能就停在了这个页面就1s时间， 就立马进入了下个路由，这时，前边这个路由的请求就可以取消掉了）
})
// axios.defaults.baseURL = '/api/'
instance.interceptors.request.use(
  function (config) {
    config.headers.Source = 1
    //所有get请求添加时间戳
    if (config.method.toLowerCase() === 'get') {
      console.log('get----------------------->')
      config['params'] = Object.assign(config['params'] ? config['params'] : {}, {
        _: new Date().valueOf(),
      })
    }
    // 重复请求校验
    removePendingRequest(config) // 检查是否存在重复请求，若存在则取消已发的请求
    addPendingRequest(config) // 把当前请求添加到pendingRequest对象中

    // 如果是 非登录页面 ，对携带 token 须校验
    // if (!config.url.includes('login')) {
    //   // const token = localStorage.getItem('token')
    // const token = store.state.token || process.env.VUE_APP_TOKEN
    const token =
      'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyYTNkOTQ2NTcxODA0YTZmYjc5Y2YxNzAwMGZjZjJjZSIsInVzZXJJZCI6ImFjY2VwdFVzZXJMb2dpbjoyYTNkOTQ2NTcxODA0YTZmYjc5Y2YxNzAwMGZjZjJjZTpjOWE0NzY0NS05MTYyLTRkNGQtYmFjYy01OTRhM2E4MjM2NmIiLCJpYXQiOjE3MjE4MDQyMzl9.BWbUUwH8rmcmvCqgOBJBy7PgBc3E6drxP1Coh5bMErY'
    //   // const token = store.state.token
    //   // 请求头携带token
    token && (config.headers.Authorization = token)
    // }

    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    config.showLoading && loading.open()
    // const token = store.state.token
    // token && (config.headers.Authorization = token)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    response.config.showLoading && loading.close()
    removePendingRequest(response.config) // 从pendingRequest对象中移除请求
    // console.log(response)
    if (response.config.responseType && response.config.responseType == 'blob') {
      if (response.data.data) {
        return Promise.resolve(response.data.data)
      } else {
        return Promise.resolve(response.data)
      }
    }
    // if (response.config.responseType && response.config.responseType == 'stream') {
    //   return Promise.resolve(response)
    // }
    if (response.data && response.data.code == 200) {
      if (response.data['data']) {
        return Promise.resolve(response.data['data'])
      } else {
        return Promise.resolve(response.data)
      }
    } else {
      tip(response.data.msg)
      return Promise.reject(response.data)
      return response
    }
  },
  (error) => {
    removePendingRequest(error.config || {}) // 从pendingRequest对象中移除请求
    // if (instance.isCancel(error)) {
    //   console.log('已取消的重复请求：' + error.message)
    // } else {
    //   // 添加异常处理
    // }
    const { response } = error
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message)
      return Promise.reject(response)
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
        store.commit('changeNetwork', false)
      } else {
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)

export default instance
