/*
 * @Description: 基础路由
 * @Author: 汉文
 * @Date: 2021-11-11 20:53:50
 * @LastEditTime: 2024-08-06 15:05:53
 * @LastEditors: 汉文
 */
export default [
  {
    path: '/',
    redirect: '/index',
    // redirect: '/login',
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "index" */ '@/views/home.vue'),
  //   meta: { title: '主页', keepAlive: false, isTabBar: false },
  // },

  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index.vue'),
    meta: { title: '知行培训', keepAlive: false, isTabBar: false },
  },
  {
    path: '/questionLibList',
    name: 'questionLibList',
    component: () => import(/* webpackChunkName: "index" */ '@/views/questionLibList.vue'),
    meta: { title: '选择题库', keepAlive: false, isTabBar: false },
  },
  {
    path: '/questionLibList',
    name: 'questionLibList',
    component: () => import(/* webpackChunkName: "index" */ '@/views/questionLibList.vue'),
    meta: { title: '选择题库', keepAlive: false, isTabBar: false },
  },
  {
    path: '/practice',
    name: 'practice',
    component: () => import(/* webpackChunkName: "index" */ '@/views/practice.vue'),
    meta: { title: '题目练习', keepAlive: false, isTabBar: false },
  },
  {
    path: '/errorquestions',
    name: 'errorquestions',
    component: () => import(/* webpackChunkName: "index" */ '@/views/practice.vue'),
    meta: { title: '错题查看', keepAlive: false, isTabBar: false },
  },
  {
    path: '/simulation',
    name: 'simulation',
    component: () => import(/* webpackChunkName: "index" */ '@/views/simulation.vue'),
    meta: { title: '题库练习', titleOr: '线上考试', keepAlive: false, isTabBar: false },
  },
  {
    path: '/competition',
    name: 'competition',
    component: () => import(/* webpackChunkName: "index" */ '@/views/competition.vue'),
    meta: { title: '线上考试', keepAlive: false, isTabBar: false },
  },
  {
    path: '/simulationResolve',
    name: 'simulationResolve',
    component: () => import(/* webpackChunkName: "index" */ '@/views/simulationResolve.vue'),
    meta: { title: '题库练习', keepAlive: false, isTabBar: false },
  },
  {
    path: '/simulationResolveFormal',
    name: 'simulationResolveFormal',
    component: () => import(/* webpackChunkName: "index" */ '@/views/simulationResolveFormal.vue'),
    meta: { title: '线上考试', keepAlive: false, isTabBar: false },
  },
  {
    path: '/choseTaskIdList',
    name: 'choseTaskIdList',
    component: () => import(/* webpackChunkName: "index" */ '@/views/taskIdList.vue'),
    meta: { title: '题库练习', keepAlive: false, isTabBar: false },
  },
  {
    path: '/answerData',
    name: 'answerData',
    component: () => import(/* webpackChunkName: "index" */ '@/views/answerData.vue'),
    meta: { title: '主页', keepAlive: false, isTabBar: false },
  },

  {
    path: '/error',
    meta: { title: 'error', keepAlive: false, isTabBar: false },
    component: () => import(/* webpackChunkName: "about" */ '@/views/error.vue'),
  },
  // {
  //   path: '/login',
  //   meta: { title: '登录中', keepAlive: false, isTabBar: false },
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/oauth/login.vue'),
  // },
]
