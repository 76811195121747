<template>
  <div id="app">
    <indexBase />
    <!-- <transition :name="$store.state.direction">
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view class="router"></router-view>
      </keep-alive>
      <router-view class="router" v-else></router-view>
    </transition>
    <Tabbar /> -->
  </div>
</template>
<script>
// import VConsole from 'vconsole'
export default {
  name: 'App',
  mounted() {
    // if (this.$utils.getQuery().debug) return new VConsole()
  },
  components: {
    indexBase: () => import('@/indexBase.vue'),

    // Tabbar: () => import('@/components/Tabbar/index.vue'),
  },
}
</script>

<style lang="scss">
#app {
  // font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #f1f4f9;
}
.router {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
