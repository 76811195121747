/*
 * @Description:
 * @Author: 汉文
 * @Date: 2021-11-10 15:24:37
 * @LastEditTime: 2023-11-21 14:45:16
 * @LastEditors: 汉文
 */
import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon' // svg组件

// 注册到全局
Vue.component('svg-icon', SvgIcon)
const files = require.context('./svg', false, /\.svg$/)
files.keys().forEach(files)
