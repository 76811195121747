import request from '@/core/request/lib/axios'
/**
 * @name:发送code登录
 * @msg:
 * @param {*} code
 * @return {*}
 */
export const login = (query) => {
  return request({
    baseURL: '/api/forward',
    url: '/streamManager/loginCode',
    method: 'get',
    params: query,
  })
}
