import request from '@/core/request/lib/axios'

/**
 * @description: 获取题库列表
 * @param {*} query
 * @return {*}
 */
export const getQuestionlibList = (query) => {
  return request({
    url: '/questionlib/list',
    method: 'get',
    params: {
      pageNum: 1,
      pageSize: 99999,
      name: '',
    },
  })
}

/**
 * @description: 获取练习题目列表
 * @param {*} query
 * @return {*}
 */
export const getQuestionList = (query) => {
  return request({
    url: '/question/list',
    method: 'get',
    params: query,
  })
}
/**
 * @description: 获取考试列表
 * @param {*} query
 * @return {*}
 */

export const getQuestionBankList = (data) => {
  return request({
    url: '/task/list',
    method: 'post',
    params: {
      pageNum: 1,
      pageSize: 9999,
    },
    data: data,
  })
}
export const addAndReturnQuestionList = (data) => {
  return request({
    url: '/paper/addAndReturnQuestionList',
    method: 'post',
    data: data,
  })
}
export const submitAnswer = (data) => {
  return request({
    url: '/answerDetail/answerQuestion',
    method: 'post',
    data: data,
  })
}
export const getAnswerErrorList = (params) => {
  return request({
    url: '/answerDetail/answerErrorList',
    method: 'get',
    params,
  })
}
export const getQuestion = (params) => {
  return request({
    url: '/question/listAndCount',
    method: 'get',
    params,
  })
}

/**
 * @description 考生考试任务(详情)
 * @param {*} query ?paperId=1&taskId=4&userId=141414124
 * @returns
 */
export const getAnswerDetail = (query) => {
  return request({
    url: '/answerDetail/getDetail',
    method: 'get',
    params: {
      ...query,
      _: new Date().getTime(),
    },
  })
}
